import { useAtom } from 'jotai'
import useStyles from './style'
import { useMediaQuery, useTheme } from '@mui/material'
import { currentSubMenuTabAtom } from '../SideMenu/components'
import { Card } from './components'
import { useRouteMatch } from 'react-router-dom'
import { CONTENT_UNIT_TEACHER_CONTENT } from 'navigation/CONSTANTS'
import { contentUnitAtom } from 'pages/ContentUnit/atomStore'
import { DisciplineCodeEnum, IUserSchoolProfileTypeEnum } from 'services/types'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import { ReactComponent as MenuNoth } from 'assets/menu-notch.svg'
import { useStore } from 'store'
import { useToggleInstructionalAtom, useToggleSideMenuAtom } from '../atomStore'
import { isCloeProActiveAtom } from 'navigation/atomStore'
import { OnlyProfile } from 'components/common'

export const BottomMenu = () => {
  const isTeacherContentPage = useRouteMatch({
    path: CONTENT_UNIT_TEACHER_CONTENT(':gradeTypeCode', ':gradeCode'),
    exact: true
  })

  const classes = useStyles()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [contentUnit] = useAtom(contentUnitAtom)
  const isCloeExpand = contentUnit?.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const [currentSubMenuTab] = useAtom(currentSubMenuTabAtom)

  const { toggleSideMenuAtom } = useToggleSideMenuAtom()
  const [, setIsOpenSideMenu] = useAtom(toggleSideMenuAtom)
  const { toggleInstructionalAtom } = useToggleInstructionalAtom()
  const [, setIsOpenInstructional] = useAtom(toggleInstructionalAtom)
  const [isCloeProActive] = useAtom(isCloeProActiveAtom)

  const { showCloePro } = useStore()

  const isEI = contentUnit?.grade?.code?.includes('EI_') &&
    !window.location.pathname.includes('/expedition')

  if (!isMobile) {
    return <></>
  }

  const menuCards = [
    {
      title: t('Explorar'),
      iconComponent: <ListAltOutlinedIcon />,
      dataTestid: '',
      onClick: () => { setIsOpenSideMenu(true) },
      disabled: false,
      profiles: [IUserSchoolProfileTypeEnum.teacher, IUserSchoolProfileTypeEnum.student]
    }
  ]

  if (isTeacherContentPage?.isExact && !isEI) {
    menuCards.push(
      {
        title: t('Instrucional'),
        iconComponent: <MapOutlinedIcon />,
        dataTestid: '',
        onClick: () => { setIsOpenInstructional(true) },
        disabled: false,
        profiles: [IUserSchoolProfileTypeEnum.teacher]
      }
    )
  }

  return (
    <div className={classes.bottomMenu}>
      <div className={classes.bottomMenuContainer}>
        <div className={`${classes.notchLeft} ${classes.notch}`}>
          <MenuNoth />
        </div>
        <div className={`${classes.notchRight} ${classes.notch}`}>
          <MenuNoth />
        </div>
        {
          menuCards?.map(({ onClick, ...tabProps }, index) => (
            <OnlyProfile key={index} profileTypes={tabProps.profiles}>
              <Card
                {...tabProps}
                key={`Card-${index}`}
                isActive={index === currentSubMenuTab}
                onClick={() => onClick()}
                dataTestid={tabProps.dataTestid}
                onlyIcon={isMobile}
                id={`card-${index}`}
              />
            </OnlyProfile>
          ))
        }
        {
          (showCloePro && isCloeProActive) && (
            <div className={classes.cloeProCardSpace} />
          )
        }
      </div>
    </div>
  )
}
