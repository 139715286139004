import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { DisciplineCodeEnum, IInstructionalContentsResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import { useContents } from '../useContents'
import isProjectViewModeRule from 'rules/isProjectViewModeRule'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import useStyle from './style'
import PrintButton from '../../PrintButton'
import { useEffect, useState } from 'react'
import ClassSection from './components/ClassSection'
import ClassMoment from './components/ClassMoment'
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import { Button, Text2 } from 'components/design-system'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useToggleInstructionalAtom } from '../../atomStore'
import { useAtom } from 'jotai'
import { hideMainNavigationAtom } from 'navigation/atomStore'

export interface IInstructionalContentsProps {
  instructional: IInstructionalContentsResponse
  contentId: string
  language: string | null
  disabledMobileFullPage?: boolean
}

export const InstructionalContents: React.FC<IInstructionalContentsProps> = ({ instructional, contentId, language, disabledMobileFullPage = false }) => {
  const { content } = useContents()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isProjectViewMode = isProjectViewModeRule(content?.grade?.grade_type ?? 0, content?.disciplines?.map(d => d.code) ?? [])
  const instructionalExpedition = content?.instructional_contents.find(cur => cur.type === 'expedition')
  const triad = instructionalExpedition && isProjectViewMode ? instructionalExpedition.sections.find(cur => cur.section_type === 'triad') ?? null : null

  const classes = useStyle()

  const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  let pages = instructional.classes
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const { toggleInstructionalAtom } = useToggleInstructionalAtom()
  const [isOpenInstructional, setIsOpenInstructional] = useAtom(toggleInstructionalAtom)
  const [, setHideMainNavigation] = useAtom(hideMainNavigationAtom)

  useEffect(() => {
    setCurrentIndex(0)
  }, [])

  useEffect(() => {
    setHideMainNavigation(isOpenInstructional)
  }, [isOpenInstructional])

  useEffect(() => {
    window.scrollTo(0, 0) // go to top

    const instructionalContainer = document.getElementById('instructionalContainer')
    if (instructionalContainer) {
      instructionalContainer.scrollTop = 0 // scroll instructionalContainer to top
    }
  }, [currentIndex])

  if (triad) {
    const triadPage = {
      id: 1,
      title: t('Tríade'),
      category: '',
      moments: [],
      skill_taxonomies: [],
      sections: [{ ...triad, title: '' }]
    }
    pages = [
      triadPage,
      ...instructional.classes
    ]
  }

  const currentPage = pages[currentIndex]
  const isFirstPage = currentIndex === 0
  const isLastPage = currentIndex === pages.length - 1

  const handleCloseInstructional = () => {
    setIsOpenInstructional(false)
  }

  const handlePreviousPage = () => {
    if (currentIndex === 0) return

    setCurrentIndex(old => old - 1)
  }

  const handleNextPage = () => {
    if (currentIndex === pages.length - 1) return

    setCurrentIndex(old => old + 1)
  }

  return (
    <Grid
      className={
        `${classes.instructionalContainer} ${!disabledMobileFullPage ? 'enabledMobileFullPage' : ''} `
      }
      id='instructionalContainer'
      sx={{
        width: disabledMobileFullPage || isOpenInstructional ? '100%' : '0',
        height: disabledMobileFullPage || isOpenInstructional ? 'auto' : '0'
      }}
    >
      <Grid className={`${classes.headerContainer}  ${!disabledMobileFullPage ? 'enabledMobileFullPage' : ''}`} item width='100%' display='flex' justifyContent='center' alignItems='center' gap={1}>
        <Grid item xs={10} sm={8} className={classes.title} display='flex' alignItems='center' gap={1}>
          {isProjectViewMode
            ? t('Conteúdo instrucional')
            : t('Instrucional')}

        </Grid>
        {
          (!isMobile || disabledMobileFullPage)
            ? (
              <Grid item xs={2} sm={4} textAlign='right'>
                <PrintButton
                  id='print_expedition_tab_instructional'
                  dataTestId='content_print_instructional'
                  content={contentId}
                  mode={IUserSchoolProfileTypeEnum.teacher}
                  lng={language}
                  printType='instructions'
                  className={classes.printButton}
                />
              </Grid>
            )
            : (
              <Grid item xs={4} sm={4} textAlign='right'>
                <Button className={classes.mobileCloseButton} onClick={handleCloseInstructional} variant='ghost'>
                  <CloseOutlinedIcon />
                </Button>
              </Grid>
            )
        }
      </Grid>
      <Grid className={classes.bodyContainer} justifyContent='space-between'>
        <div className={classes.navigationHeaderContainer}>
          <Text2 fontSize='lg' fontWeight='semibold' lineHeight='xs' mobile='lg' information='feedbackDarkest'>
            {currentPage.title}
          </Text2>
          <div className={classes.navigationHeaderButtons}>
            <Button
              onClick={handlePreviousPage}
              variant='outlined'
              className={classes.navigationHeaderButton}
              disabled={isFirstPage}
            >
              <ArrowBackIosOutlinedIcon />
            </Button>
            <Button
              onClick={handleNextPage}
              variant='outlined'
              className={classes.navigationHeaderButton}
              disabled={isLastPage}
            >
              <ArrowForwardIosOutlinedIcon />
            </Button>
          </div>
        </div>
        {
          currentPage.sections.map(section =>
            <ClassSection
              key={section.id}
              section={section}
            />
          )
        }
        {
          currentPage.moments.map(moment =>
            <ClassMoment
              key={moment.id}
              moment={moment}
            />
          )
        }
        <div className={classes.navigationFooterButtons}>
          <Button
            onClick={handlePreviousPage}
            startIcon={<ArrowBackIosOutlinedIcon />}
            variant='outlined'
            className={classes.navigationFooterButton}
            disabled={isFirstPage}
          >
            {t('Anterior')}
          </Button>
          <Button
            onClick={handleNextPage}
            endIcon={<ArrowForwardIosOutlinedIcon />}
            variant='outlined'
            className={classes.navigationFooterButton}
            disabled={isLastPage}
          >
            {t('Próximo')}
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

export default InstructionalContents
