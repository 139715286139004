import { useParams, useRouteMatch } from 'react-router-dom'
import { CONTENT_UNIT_STUDENT_CONTENT, CONTENT_UNIT_TEACHER, CONTENT_UNIT_TEACHER_CONTENT } from 'navigation/CONSTANTS'
import { DisciplineCodeEnum, IContentUnitResponse, IContentUnitStatus, ICoverResponse } from 'services/types'

import useStyles, { IStyleProps } from './style'
import { Button, Tag, Text2 } from 'components/design-system'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { useEffect, useState } from 'react'
import { getContentUnitProgress } from 'services/content-unit'
import { ContentUnitStatus } from 'pages/Dashboard/teacher/Contents/components/ListContentUnit/components/ContentCardLong/components/Card/components/ContentUnitStatus'
import { ContentUnitSubmenu } from './components/ContentUnitSubmenu'
import { toast } from 'components/design-system/Toast/manager'
import { StatusSelector } from './components'
import { useAtom } from 'jotai'
import { useToggleSideMenuAtom } from '../atomStore'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useMediaQuery, useTheme } from '@mui/material'
import { BackLink } from '../BackLink'
import { useCurricularStore } from 'store'
import { hideMainNavigationAtom } from 'navigation/atomStore'

interface ISideMenuProps {
  contentUnit: IContentUnitResponse
}

export interface ISideMenuStyleProps {
  imageUrl: ICoverResponse
}

export const SideMenu = ({ contentUnit }: ISideMenuProps) => {
  const isTeacherOverviewView = useRouteMatch({
    path: CONTENT_UNIT_TEACHER(':gradeTypeCode', ':gradeCode'),
    exact: true
  })
  const isContentViewTeacher = useRouteMatch({
    path: CONTENT_UNIT_TEACHER_CONTENT(':gradeTypeCode', ':gradeCode'),
    exact: true
  })
  const isContentViewStudent = useRouteMatch({
    path: CONTENT_UNIT_STUDENT_CONTENT(),
    exact: true
  })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { toggleSideMenuAtom } = useToggleSideMenuAtom()

  const [isOpenSideMenu, setIsOpenSideMenu] = useAtom(toggleSideMenuAtom)
  const [, setHideMainNavigation] = useAtom(hideMainNavigationAtom)

  const stylesProps: IStyleProps = {
    imageUrl: contentUnit.cover,
    hasNavigationTabs: !!isContentViewTeacher || !!isContentViewStudent
  }

  const classes = useStyles(stylesProps)
  const isCloeExpand = contentUnit.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const { contentId, classId: classIdParams } = useParams<{ contentId: string, gradeTypeCode: string, gradeCode: string, classId: string }>()
  const { classId: classIdCurricularStore } = useCurricularStore()

  const classId = classIdParams ?? classIdCurricularStore

  const [contentUnitProgress, setContentUnitProgress] = useState<IContentUnitStatus>('IDLE')

  const fetchContentUnitProgress = async () => {
    try {
      const response = await getContentUnitProgress(Number(contentId), Number(classId))

      if (!response.success && response.status !== 400) {
        throw new Error(t('Ocorreu um erro ao tentar resgatar o seu progresso nessa unidade de conteúdo'))
      }

      setContentUnitProgress(response.data?.status ?? 'IDLE')
    } catch (err: any) {
      toast.handler({
        content: err.message,
        duration: 5000,
        severity: 'error'
      })
    }
  }

  const handleCloseSideMenu = () => {
    setIsOpenSideMenu(false)
  }

  useEffect(() => {
    fetchContentUnitProgress()
  }, [])

  useEffect(() => {
    if (isMobile) {
      handleCloseSideMenu()
    }
  }, [isMobile])

  useEffect(() => {
    setHideMainNavigation(isOpenSideMenu)
  }, [isOpenSideMenu])

  return (
    <div className={`${classes.sideMenu} ${isOpenSideMenu ? '' : classes.sideMenuClosed}`}>
      {!isMobile && (
        <BackLink />
      )}

      <div className={classes.backgroundImageContainer}>
        <div className={classes.backgroundImage} />
      </div>

      <header className={classes.titleContainer}>
        <div className={classes.titleGroup}>
          {!isMobile && (
            <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='dark40'>
              {t('Unidade')} {contentUnit.suggested_position}
            </Text2>
          )}
          <Text2 fontSize='lg' fontWeight='semibold' lineHeight='xs' mobile='lg' colorbrand={isMobile ? 'dark' : 'medium'}>
            {contentUnit.title}
          </Text2>
        </div>

        {isMobile && (
          <Button className={classes.mobileCloseButton} onClick={handleCloseSideMenu} variant='ghost'>
            <CloseOutlinedIcon />
          </Button>
        )}
      </header>

      <div className={classes.disciplinesStatusContainer}>
        {
          contentUnit.disciplines.map(d =>
            <Tag key={d.id} variant={d.code}>
              {t(d.name)}
            </Tag>
          )
        }

        <ContentUnitStatus className={classes.contentUnitStatus} fetchData={false} initialStatus={contentUnitProgress} />
      </div>

      <div className={classes.flexContent}>
        <ContentUnitSubmenu />
      </div>

      {
        isTeacherOverviewView && (
          <StatusSelector
            contentUnitId={contentId}
            classId={classId}
            initialStatus={contentUnitProgress}
            setSelected={setContentUnitProgress}
          />
        )
      }
    </div>
  )
}
