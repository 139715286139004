import { useAtom } from 'jotai'
import React, { Fragment, ReactNode } from 'react'
import { renderChildrenWithProps } from 'utils/wrappers'
import { enabledFeaturesAtom } from './atomStore'

export enum FeatureFlags {
  testFlag = 'test-flag',
  newLateralTipsButton = 'new-lateral-tips-button',
  cloePro = 'cloe-pro'
}

export const hasFeatureFlag = () => {
  const [enabledFeatures] = useAtom(enabledFeaturesAtom)

  const isFeatureFlagEnabled = (featureFlag: string) => {
    if (!enabledFeatures) {
      return false
    }

    return !!enabledFeatures.find(ff => ff === featureFlag)
  }

  return {
    isFeatureFlagEnabled
  }
}

interface IFeatureToggleProps {
  feature: FeatureFlags
  [propName: string]: any
}

export const FeatureToggle: React.FC<IFeatureToggleProps> = ({ children, feature, ...props }) => {
  const [enabledFeatures] = useAtom(enabledFeaturesAtom)

  const featureToggleEnabled = () => {
    if (!enabledFeatures || !Array.isArray(enabledFeatures)) {
      return false
    }
    const isEnabled = enabledFeatures.find(feat => feat === feature)
    return !!isEnabled
  }

  const toggledChildren = React.Children.map(children, (child: any) => React.cloneElement(child, {
    featureToggleEnabled: featureToggleEnabled(),
    props
  }))

  return (
    <React.Fragment>
      {toggledChildren}
    </React.Fragment>
  )
}

interface IFeatureTogleComponent {
  children: ReactNode
  featureToggleEnabled?: boolean
  props?: {
    [propName: string]: any
  }
}

export const On: React.FC<IFeatureTogleComponent> = ({ children, featureToggleEnabled, props }) => {
  if (!featureToggleEnabled) {
    return <></>
  }

  const renderChildren = renderChildrenWithProps<IFeatureTogleComponent>({ children, props })

  return (
    <Fragment>
      {renderChildren}
    </Fragment>
  )
}

export const Off: React.FC<IFeatureTogleComponent> = ({ children, featureToggleEnabled, props }) => {
  if (featureToggleEnabled) {
    return <></>
  }

  const renderChildren = renderChildrenWithProps<IFeatureTogleComponent>({ children, props })

  return (
    <Fragment>
      {renderChildren}
    </Fragment>
  )
}

export default FeatureToggle
